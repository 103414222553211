import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"https://connect.facebook.net/de_DE/sdk.js#xfbml=1&appId=272376829538322&version=v2.0\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));"
          }}
        />
        <Header></Header>
        <div id="meganav">
          <div className="container">
            {" "}
            <a
              href="/"
              title="bebe Zartpflege®"
              target="_self"
              className="navbar-brand"
            >
              <em>
                bebe Zartpflege<sup>®</sup>
              </em>
            </a>
            <div className="close-meganav" />
            <div className="col-xs-12 meganav-top">
              {" "}
              <a
                href="/"
                target="_self"
                title="Start"
                className="navitem"
              >
                Start
              </a>{" "}
              <a
                href="/menu/kontakt/"
                target="_self"
                title="Kontakt"
                className="navitem"
              >
                Kontakt
              </a>{" "}
              <a
                href="/menu/faq/"
                target="_self"
                title="FAQ"
                className="navitem"
              >
                FAQ
              </a>{" "}
              <a
                href="/menu/impressum/"
                target="_self"
                title="Impressum"
                className="navitem"
              >
                Impressum
              </a>{" "}
              <a
                href="/menu/rechtliche-hinweise/"
                target="_self"
                title="Rechtliche Hinweise"
                className="navitem"
              >
                Rechtliche Hinweise
              </a>{" "}
              <a
                href="/menu/cookie-information/"
                target="_self"
                title="Cookies"
                className="navitem"
              >
                Cookies
              </a>{" "}
              <a
                href="/menu/allg-gewinnspielbedingungen/"
                target="_self"
                title="Allg. Gewinnspielbedingungen"
                className="navitem"
              >
                Allg. Gewinnspielbedingungen
              </a>{" "}
            </div>
            <div id="col-products" className="cat-links col-xs-12 col-sm-3">
              {" "}
              <a
                className="cat-thumb"
                href="/produkte/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.produkte.png"
                  alt="Produkte"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/produkte/"
                    target="_self"
                    title="Produkte"
                  >
                    Produkte
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/produkte/#ZartcremeinderDose"
                        target="_self"
                      >
                        Zartcreme
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#schaumbad-und-dusche"
                        target="_self"
                      >
                        Schaumbad und Dusche
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#Glanzshampoo"
                        target="_self"
                      >
                        Glanzshampoo
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#Pflegelotion"
                        target="_self"
                      >
                        Pflegelotion
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#MildeHandseife"
                        target="_self"
                      >
                        Milde Handseife
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#shampoo"
                        target="_self"
                      >
                        Stärkendes Shampoo und Spülung
                      </a>
                    </li>
                    <li>
                    <a
                      href="/produkte/#Lippenpflegemit"
                      target="_self"
                    >  
                     Lippenpflege mit fruchtigem Kirschduft
                    </a>
                </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div
              id="col-unsere-maedels"
              className="cat-links col-xs-12 col-sm-3"
            >
              {" "}
              <a
                className="cat-thumb"
                href="/unsere-maedels/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.unsere-maedels.png"
                  alt="Unsere Mädels"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/unsere-maedels/"
                    target="_self"
                    title="Unsere Mädels"
                  >
                    Unsere Mädels
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/unsere-maedels/#BibiBlubberblase"
                        target="_self"
                      >
                        Bibi Blubberblase
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#MiaMorgentau"
                        target="_self"
                      >
                        Mia Morgentau
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#PaulaPerlensanft"
                        target="_self"
                      >
                        Paula Perlensanft
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#ViviWallemaehne"
                        target="_self"
                      >
                        Vivi Wallemähne
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#SophieSonnenschein"
                        target="_self"
                      >
                        Sophie Sonnenschein
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#Malbuch"
                        target="_self"
                      >
                        Mal- &amp; Rätselbuch
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div id="col-marken" className="cat-links col-xs-12 col-sm-3">
              {" "}
              <a
                className="cat-thumb"
                href="/markenhistorie/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.markenhistorie.png"
                  alt="Markenhistorie"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/markenhistorie/"
                    target="_self"
                    title="Markenhistorie"
                  >
                    Markenhistorie
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a href="/markenhistorie/#60er" target="_self">
                        60er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#70er" target="_self">
                        70er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#80er" target="_self">
                        80er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#90er" target="_self">
                        90er
                      </a>
                    </li>
                    <li>
                      <a
                        href="/markenhistorie/#2000er"
                        target="_self"
                      >
                        2000er
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div
              id="col-limited-editions"
              className="cat-links col-xs-12 col-sm-3"
            >
              {" "}
              <a
                className="cat-thumb"
                href="/limited-editions/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.limited_editions.png"
                  alt="Limited Editions"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/limited-editions/"
                    target="_self"
                    title="Limited Editions"
                  >
                    Limited Editions
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/limited-editions/#OnlineGame"
                        target="_self"
                      >
                        Online Game
                      </a>
                    </li>
                    <li>
                      <a
                        href="/limited-editions/#LimitedEditions"
                        target="_self"
                      >
                        Limited Editions
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 meganav-mobile-bottom">
              {" "}
              <a
                className="navitem"
                title="Start"
                target="_self"
                href="/"
              >
                Start
              </a>{" "}
              <a
                className="navitem"
                title="Kontakt"
                target="_self"
                href="/menu/kontakt/"
              >
                Kontakt
              </a>{" "}
              Newsletter{" "}
            </div>
          </div>
        </div>
        <div id="content">
          <div className="container">
            <div id="teaser-produkte" className="row text-center teaser-item">
              <div className="teaser-text col-md-10 col-md-offset-1">
                {" "}
                <a href="/produkte/">
                  <h1>Unsere Produkte</h1>
                </a>
                <p>
                  <strong
                    style={{
                      "line-height": "1.42857143"
                    }}
                  >
                    bebe Zartpflege<sup>®</sup>
                  </strong>{" "}
                  ist speziell auf die Bedürfnisse junger Haut abgestimmt und
                  pflegt sie zart und geschmeidig – seit mehr als 50 Jahren.
                </p>
              </div>
              <div className="teaser-image">
                {" "}
                <a href="/produkte/">
                  {" "}
                  <img
                    className="mobile"
                    alt="bebe Produkte"
                    src="/fileadmin/images/produkte/startseite.teaser.produkte.mobile.png"
                    width="640"
                    height="390"
                  />
                  <img
                    className="desktop"
                    alt="bebe Produkte"
                    src="/fileadmin/images/produkte/705853_DE_Bebe-Zartpflege-Website-Banner_v3.png"
                    width="980"
                    height="337"
                  />{" "}
                </a>{" "}
              </div>
            </div>
            <div
              id="teaser-markenhistorie"
              className="row text-center teaser-item"
            >
              <div className="teaser-text col-md-10 col-md-offset-1">
                <h2>
                  <a href="/markenhistorie/">Markenhistorie</a>
                </h2>
                <p>
                  Seit mehr als 50 Jahren pflegt{" "}
                  <strong>
                    bebe<sup>®</sup>
                  </strong>{" "}
                  junge Haut zart und geschmeidig: Die Geschichte von{" "}
                  <strong>
                    bebe Zartpflege<sup>®</sup>
                  </strong>
                  .
                </p>
              </div>
              <div className="teaser-image">
                {" "}
                <a href="/markenhistorie/">
                  {" "}
                  <img
                    src="/fileadmin/images/startseite.teaser.markenhistorie.desktop.4.png"
                    width="924"
                    height="367"
                    alt
                    className="desktop"
                  />
                  <img
                    src="/fileadmin/images/startseite.teaser.markenhistorie.mobile.2.png"
                    width="640"
                    height="391"
                    alt
                    className="mobile"
                  />
                </a>{" "}
              </div>
            </div>
            <p className="small">
              {" "}
              Nach Waldemar Bonsels “Die Biene Maja und ihre Abenteuer” und
              “Himmelsvolk”
              <br /> © Studio 100 Media, Buzz Studios, Screen Australia and
              Screen NSW.
              <br />{" "}
              <a
                href="http://www.diebienemaja-derfilm.de/"
                target="_blank"
                className="external-link-new-window"
              >
                www.DieBieneMaja-DerFilm.de
              </a>{" "}
              -{" "}
              <a
                href="http://www.studio100.de/"
                target="_blank"
                className="external-link-new-window"
              >
                www.studio100.de
              </a>{" "}
              - ™ Studio 100
              <br />{" "}
            </p>
            <div
              id="teaser-limited-editions"
              className="row text-center teaser-item"
            >
              <div className="teaser-text col-md-10 col-md-offset-1">
                <h2>
                  <a href="/limited-editions/">Limited Editions</a>
                </h2>
                <p>
                  Süße Motive zieren seit 1995 die
                  <strong>
                    {" "}
                    bebe Zartpflege<sup>®</sup>
                  </strong>{" "}
                  Produkte, die dadurch zum beliebten Sammlerobjekt geworden
                  sind – entdecke hier die Welt der{" "}
                  <strong>
                    bebe Zartpflege<sup>®</sup>
                  </strong>{" "}
                  Limited Editions.
                </p>
              </div>
              <div className="teaser-image">
                {" "}
                <a href="/limited-editions/">
                  {" "}
                  <img
                    src="/fileadmin/images/startseite.teaser.limited_editions.desktop.2.png"
                    width="926"
                    height="415"
                    alt
                    className="desktop"
                  />
                  <img
                    src="/fileadmin/images/startseite.teaser.limited_editions.mobile.png"
                    width="443"
                    height="446"
                    alt
                    className="mobile"
                  />
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          src="/fileadmin/js/vendor/jquery6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/detectmobilebrowser6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/bootstrap.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/jquery.ba-throttle-debounce.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/skrollr.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/isMobile.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/jquery.scrollTo.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/script71c5.js?1527068951"
          type="text/javascript"
        />
      </div>
    );
  }
}

export default Page;
